import Network from '@/services/network'
import { ref } from 'vue'

export default function handlePayment() {
    const network = new Network;
    const paymentLoading = ref(false)

    const fetchSinglePayment = (ledgerMasterId) => {
      return network.api('get', '/payments/'+ledgerMasterId);
    }

    const createPayment = (data) => {
      return network.api('post', '/payments', data);
    }

    const editPayment = (id, data) => {
      return network.api('post', '/payments/'+id, data);
    }

    const fetchCashAndBank = (companyId) => {
      return network.api('get', '/heads/cash-and-banks?company_id='+companyId);
    }

    const fetchAccountHeads = (companyId) => {
      return network.api('get', '/account-heads?company_id='+companyId);
    }

    return {
        fetchSinglePayment,
        fetchCashAndBank,
        createPayment,
        fetchAccountHeads,
        editPayment,
        paymentLoading
    }
}